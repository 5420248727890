<template>
  <div class="lostworks">
    <h1>Lost Works</h1>
     <div class="lostworks-wrapper">
       <div class="col-first">
        <div class="compressed-image">
          <img :src="largeImage">
        </div>
        <div class="col-second">
          <div class="images">
            <div class="image"> 
                <img class="lostworks-sm-img" :src="smallImageFirst">
              </div>
            <div class="image second-image">
              <img class="lostworks-sm-img" :src="smallImageSecond">
            </div>
          </div>
        </div>
       </div>
        <div class="col-third">
          <div class="lostworks-text" v-html="lostWorkData.pageText"></div>
        </div>
    </div>
  </div>
</template>

<script>
import API from '../services/api'

  export default {
  data() {
    return {
      lostWorkData: '',
      lostWorkImages: '',
      largeImage: '',
      smallImageFirst: '',
      smallImageSecond: '',

    }
  },
  mounted() {
   this.init()
    
  },
  methods:{
    init(){
      this.getContent();
      
    },
    async getContent(){
      API.lostWorks().then(data => {
        this.order_artwork(data[0]);
        
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
    order_artwork(lwData) {
      this.lostWorkData = lwData
      let lwImages = lwData.imageFile
      console.log(lwImages);
        for(let i = 0; i < lwImages.length; i++) {
          if(i == 0) {
              this.largeImage = lwImages[i].imageFileRefCompressed;
            }
          else if(i == 1) {
            this.smallImageFirst = lwImages[i].imageFileRefCompressed;
          }
          else if(i == 2) {
            console.log(lwImages[i]);
            this.smallImageSecond = lwImages[i].imageFileRefCompressed;
          }
        }        
    },
  }
}
</script>


<style lang="scss" scoped>
.lostworks {
  p {
    margin-top: 0px;
  }
  margin-top: 140px;
  height: 100%;
  margin-left: 75px;
  margin-right: 75px;
  padding-top: 10px;
  @media (max-width: 550px) {
    margin-top: 100px;
    margin-left: 5px;
    margin-right: 5px;
  }
  h1 {
    text-align: left;
    color: #801818;
  }
  .lostworks-wrapper {
    display: flex;
    width: 100%;
    @media (max-width: 1100px) {
      display: inline;
    }
    .col-first {
      width: 80%;
      .compressed-image {
        height: max-content;
      }
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
        
      }
      @media (max-width: 1200px) {
          width: 40%;
      }
      @media (max-width: 1000px) {
          width: 100%;
      }
    }
    .col-second {
      margin-top: 25px;
      text-align: left;
      display: flex;
      width: 100%;
      .images{
        position: relative;
        height: 100%;
        justify-content: space-between;
        display: flex;
        .image{
          width:48%;
        }
        .second-image{
          width: 48%;
        }
      }
      @media (max-width: 1100px) {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 50px;
        }
      .lostworks-sm-img {
        width: 100%;
        height: auto;
        margin: 0 auto;
        
        @media (max-width: 700px) {
          width: 100%;
        }
        
      }
      @media (max-width: 1200px) {
          width: 50%;
          margin: 5px
      }
      @media (max-width: 1000px) {
        width: 100%;
        margin-top: 25px;
      }
    }
    .col-third{
      width: 15%;
      margin: 0 auto;
      .lostworks-text {
        padding-bottom: 50px;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.5;
        text-align: left;
        p {
          background-color: red;
          margin-top: 0;
        }
        @media (max-width: 1300px) {
          font-size: 16px;
        }
        @media (max-width: 1200px) {
          font-size: 18px;
        }
        @media (max-width: 1100px) {
          padding: 50px 0;
        }
        @media (max-width: 700px) {
          padding: 12.5px 0;
        }
        @media (max-width: 550px) {
          font-size: 16px;
        }
      }
      @media (max-width: 1200px) {
          width: 100%;
      }
    }
    @media (max-width: 1200px) {
        display: flex;
        flex-wrap: wrap;
    }
    
    @media (max-width: 1000px) {
      display: block;
    }
  }
}

</style>